<template>
    <section>
        <div id="downloadModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal(false)"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-large">Download Template Premium via Disburse</h2>
                </div>
                <form @submit.prevent="submitDownload">
                    <div class="uk-padding-small uk-grid-small" uk-grid>
                        <div class="uk-width-1-1">
                            <label for="company" class="uk-form-label uk-text-default">Company <span
                                    class="uk-text-danger">*</span></label>
                            <multiselect id="company" :multiple="false" :searchable="true" :internal-search="false"
                                :loading='isFetchingCompany' :options="companyOptions" track-by="_id" label="name"
                                placeholder="Select Company" data-vv-name="company_id" data-vv-scope="form"
                                v-validate="{ required: true }" v-model="form.company_id"
                                @search-change="filterCompanyByName" />

                            <span class="uk-text-small uk-text-danger" v-show="errors.has('form.company_id')">
                                {{ errors.first('form.company_id') }}
                            </span>
                        </div>
                        <div class="uk-width-1-2">
                            <label for="disbursed_at" class="uk-form-label uk-text-default">Disbursed At <span
                                    class="uk-text-danger">*</span></label>
                            <datetime id="disbursed_at" type="date" input-class="uk-button uk-text-left"
                                format="dd LLLL yyyy" data-vv-name="disbursed_at" data-vv-scope="form"
                                v-validate="{ required: true }" v-model="form.disbursed_at"
                                :class="{ 'uk-form-danger': errors.has('form.disbursed_at') }" />

                            <span class="uk-text-small uk-text-danger" v-show="errors.has('form.disbursed_at')">
                                {{ errors.first('form.disbursed_at') }}
                            </span>
                        </div>
                        <div class="uk-width-1-1">
                            <label class="uk-form-label uk-text-default">Premium Membership Payment Method : <span
                                    class="uk-text-bold">Disburse Cut</span></label>
                        </div>
                        <div class="uk-width-1-1">
                            <label class="uk-form-label uk-text-default">Product : <span class="uk-text-bold">Premium
                                    Individual</span></label>
                        </div>
                        <div class="uk-width-1-1">
                            <label class="uk-form-label uk-text-default">Disburse Cut : <span class="uk-text-bold">Rp.
                                    15.000</span></label>
                        </div>
                    </div>

                    <!-- footer button -->
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <section>
                            <button class="uk-button uk-button-default uk-border-rounded uk-margin-right uk-text-primary"
                                type="button" style="border-color: #0f7ae5;" @click="hideModal(false)">
                                Cancel
                            </button>
                            <button class="uk-button uk-border-rounded" type="submit"
                                :class="[disableSaveButton ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disableSaveButton">
                                Download
                            </button>
                        </section>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import { Datetime } from 'vue-datetime';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { excelDownloader } from "@/utils/helper";

export default {
    components: {
        Datetime,
    },
    data: () => ({
        form: {
            company_id: null,
            disbursed_at: null,
        },
        companyOptions: [],
        metaCompany: { name: '' },
        isFetchingCompany: false,
        isDownloading: false,
    }),
    watch: {
        async "metaCompany.name"() {
            try {
                if (this.metaCompany.name.length < 3) {
                    return;
                }

                this.isFetchingCompany = true;
                await this.setCompanyOptions();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetchingCompany = false;
            }
        },
    },
    computed: {
        disableSaveButton() {
            return (
                !this.form.company_id ||
                !this.form.disbursed_at
            ) || this.errors.any();
        },
        payload() {
            let payload = {};
            if (this.form.company_id?._id) payload.company_id = this.form.company_id._id;
            if (this.form.disbursed_at) payload.disbursed_at = this.form.disbursed_at;
            return payload;
        },
    },
    async mounted() {
        window.UIkit.modal('#downloadModal').show();

        try {
            await this.setCompanyOptions();
        } catch (error) {
            notificationDanger(error);
        }
    },
    methods: {
        ...mapActions({
            getCompanyOptionList: 'companyV4/getCompanyOptionList',
            downloadTemplate: 'membershipV4/downloadMembershipTemplate',
        }),
        async setCompanyOptions() {
            try {
                this.isFetchingCompany = true;
                const response = await this.getCompanyOptionList(this.metaCompany);
                if (response?.data?.status === 'OK' && response?.data?.result) {
                    this.companyOptions = response.data.result || [];
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetchingCompany = false;
            }
        },
        async submitDownload() {
            try {
                this.isDownloading = true;
                const payload = this.payload;
                const response = await this.downloadTemplate(payload);
                
                if (response?.status !== 'OK' || !response?.result) {
                    throw new Error(response?.data?.message || 'Failed to download template');
                }
                
                excelDownloader(response.result, `${this.form.company_id?.name || 'CompanyName'}_Premium Membership Template.xlsx`);
                notificationSuccess('Success download template!');
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isDownloading = false;
                this.hideModal(true);
            }
        },
        resetForm() {
            this.form.company_id = null;
            this.form.disbursed_at = null;

            this.$validator.reset();
        },
        hideModal(isRefresh) {
            this.resetForm();
            window.UIkit.modal('#downloadModal').$destroy(true);
            this.$emit('hideModal', isRefresh);
        },
        filterCompanyByName(query) {
            if (query.length < 3) {
                return;
            }
            
            this.metaCompany.name = query;
        },
    }
};
</script>
